import Server from "./models/server";

export function sortServersAlphabetically(a: Server, b: Server) {
        const serverNameA = a.serverName.toUpperCase();
        const serverNameB = b.serverName.toUpperCase();
        return (serverNameA < serverNameB) ? -1 : (serverNameA > serverNameB) ? 1 : 0;
}

export async function fetchServers(accessToken: string): Promise<Server[]> {
    let response: Response
    let json: { data: Server[]; }
    let processedServers: Server[] = []

    try {
        response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/servers`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        json = await response.json()
    } catch (error) {
        console.log(error)
        return []
    }

    await Promise.all(json.data.map(async (server: Server) => {
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/health?serverName=${server.serverName}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })

        const healthJson = await response.json()

        server.status = healthJson.data.health
        server.players = healthJson.data.players
        processedServers.push(server)
    }))

    return processedServers.sort(sortServersAlphabetically)
}

export function serverTypeFromIdent(ident: string) {
    switch (ident) {
        case 'AUTO_CURSEFORGE':
            return 'Curse Modpack'
        case 'PUFFERFISH':
            return 'Paper'
        default:
            return 'Unknown'
    }
}

export function modpackFromModpackUrl(serverType: string, url: string) {
    switch (serverType) {
        case 'AUTO_CURSEFORGE':
            const tokens = url.split('/')
            return tokens[tokens.length-1]
        default:
            return ''
    }
}

export function isAdmin(userNickname: string) {
    return ['matiasrjensen', 'lagsdk'].some((name) => name === userNickname)
}