import { useAuth0 } from "@auth0/auth0-react"
import { Container, Button } from "@mui/material"
import Server from "./models/server"

interface DeleteDeviceProps {
    device: Server
    closeHandler: Function
}

export default function DeleteDevice(props: DeleteDeviceProps) {
    
    const { getAccessTokenSilently } = useAuth0()

    function DeleteServer(accessToken: string, serverName: string) {
        fetch(`${process.env.REACT_APP_API_DOMAIN}/delete?serverName=${serverName}`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(async response => {
            console.log(response)
    
            const json = await response.json()
            console.log(json)
        })
        .catch(error => {
            console.log(error)
        })
    }

    return (
    <div>
        <Container maxWidth="sm">
            <h1>Delete {props.device.serverName}?</h1>
            <Button variant="contained" color="error" onClick={() => props.closeHandler()}>Cancel</Button>
            <Button variant="contained" color="success" onClick={async () => {
                DeleteServer(await getAccessTokenSilently(), props.device.serverName)
                props.closeHandler()
            }}>Confirm</Button><br />
            <br />
        </Container>
    </div>)
}