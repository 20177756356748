import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <div
    style={{
        position: 'absolute', left: '50%', top: '2%',
        transform: 'translate(-50%, 0)'
    }}>
      <Auth0Provider
        domain={`${process.env.REACT_APP_AUTH_DOMAIN}`}
        clientId={`${process.env.REACT_APP_AUTH_CLIENT_ID}`}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: `${process.env.REACT_APP_API_DOMAIN}`
        }}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
        </ThemeProvider>
      </Auth0Provider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
