import { Container, TextField, Button, Typography, styled, TooltipProps, Tooltip, tooltipClasses, Select, MenuItem } from "@mui/material"
import React, { useEffect } from "react"
import { useState } from "react"
import HelpIcon from '@mui/icons-material/Help';

interface CreateServerProps {
    submitHandler: (serverName: string, serverType: string, modpackUrl: string, minecraftVersion: string) => Promise<void>
    closeHandler: () => void
}

export default function CreateServer(props: CreateServerProps) {

    class ValidationErrors {
        public serverName = true
        public serverType = true
        public modpackUrl = true
        public minecraftVersion = true
        
        public get any() {
            return this.serverName || this.serverType || this.modpackUrl || this.minecraftVersion
        }
    }

    const [validationErrors, setValidationErrors] = useState<ValidationErrors>(new ValidationErrors())
    const [serverName, setServerName] = useState('')
    const [serverType, setServerType] = useState('AUTO_CURSEFORGE')
    const [modpackUrl, setModpackUrl] = useState('')
    const [minecraftVersion, setMinecraftVersion] = useState('')

    useEffect(() => {
        validateInput()
      }, [serverName, serverType, modpackUrl, minecraftVersion]);

    function validateInput() {
        const validationErrors = new ValidationErrors()

        validationErrors.serverName = !serverName || /\s/.test(serverName)
        validationErrors.serverType = !serverType
        validationErrors.modpackUrl = serverType === "AUTO_CURSEFORGE" && !modpackUrl
        validationErrors.minecraftVersion = !minecraftVersion

        setValidationErrors(validationErrors)
    }

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 450,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));

    return (
    <div>
        <Container maxWidth="sm">
            <h1>Add new server</h1>
            <TextField size="small" label="Server Name" required error={validationErrors.serverName} value={serverName} onChange={ (event) => setServerName(event.target.value) } /><br />
            <br />
            <label>Server Type</label><br />
            <Select size="small" displayEmpty required value={serverType} error={validationErrors.serverType} onChange={(event) => setServerType(event.target.value)}>
                <MenuItem value="AUTO_CURSEFORGE">Curse modpack</MenuItem>
                <MenuItem value="PUFFERFISH">Paper</MenuItem>
            </Select><br />
            <br />
            { serverType === "AUTO_CURSEFORGE" && 
            <div>
                <TextField size="small" label="Modpack Link" required error={validationErrors.modpackUrl} value={modpackUrl} onChange={ (event) => setModpackUrl(event.target.value) } />
                <HtmlTooltip title={
                    <React.Fragment>
                        <Typography color="inherit">Where to find modpack url</Typography>
                        <img width="400" height="50" src="modpack-url-example.png" />
                    </React.Fragment>
                    }>
                    <HelpIcon />
                </HtmlTooltip><br />
            <br />
            </div>
            }
            { serverType === "AUTO_CURSEFORGE" ?
            <div>
                <TextField size="small" label="Minecraft Version" required error={validationErrors.minecraftVersion} value={minecraftVersion} onChange={ (event) => setMinecraftVersion(event.target.value) } />
                <HtmlTooltip title={
                    <React.Fragment>
                        <Typography color="inherit">Where to find minecraft version</Typography>
                        <img width="200" height="50" src="game-version.png" />
                    </React.Fragment>
                    }>
                    <HelpIcon />
                </HtmlTooltip><br />
            </div>
            :
            <div>
                <TextField size="small" label="Minecraft Version" required error={validationErrors.minecraftVersion} value={minecraftVersion} onChange={ (event) => setMinecraftVersion(event.target.value) } />
                <br />
            </div>
            }
            <br />
            <Button variant="contained" color="error" onClick={() => props.closeHandler()}>Cancel</Button>
            <Button disabled={validationErrors.any} variant="contained" color="success" onClick={async () => {
                props.submitHandler(serverName, serverType, modpackUrl, minecraftVersion);
                props.closeHandler()
            }}>Add Server</Button><br />
            <br />
        </Container>
    </div>)
}