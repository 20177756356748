import { IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import StopIcon from '@mui/icons-material/Stop';
import { useEffect, useState } from "react";
import Server from "./models/server";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchServers, isAdmin, modpackFromModpackUrl, serverTypeFromIdent } from "./util";

interface ControlPanelProps {
    showOwnServers: boolean
}

export default function ControlPanel(props: ControlPanelProps) {

    const [servers, setServers] = useState<Server[]>([])
    const { user, getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        getAccessTokenSilently().then(async token => {
            setServers(await fetchServers(token))
        })
        
        const interval = setInterval(async () => {
            setServers(await fetchServers(await getAccessTokenSilently()))
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, []);

    async function startServer(serverName: string) {
        const token = await getAccessTokenSilently()
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/start?serverName=${serverName}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        const json = await response.json()
        
        console.log(response)
        console.log(json)
        
        setTimeout(async () => {
            await fetchServers(token)
        }, 5000)
    }

    async function restartServer(serverName: string) {
        const token = await getAccessTokenSilently()
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/restart?serverName=${serverName}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        const json = await response.json()
        
        console.log(response)
        console.log(json)
        
        setTimeout(async () => {
            await fetchServers(token)
        }, 5000)
    }

    async function stopServer(serverName: string) {
        const token = await getAccessTokenSilently()
        const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/stop?serverName=${serverName}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        const json = await response.json()
        
        console.log(response)
        console.log(json)
        
        setTimeout(async () => {
            await fetchServers(token)
        }, 5000)
    }

    const listItems = servers.filter((server: Server) => {
        if (!props.showOwnServers) {
            return true
        }

        return server.creator === user?.nickname
    })
    .map((server: Server) => {
        return (
            <TableRow key={server.serverName}>
                {server.creator === user?.nickname || isAdmin(user?.nickname!) ?
                <TableCell>
                    <IconButton onClick={async () => await startServer(server.serverName)}>
                        <PlayArrowIcon color="success"></PlayArrowIcon>
                    </IconButton>
                    <IconButton onClick={async () => await restartServer(server.serverName)}>
                        <RestartAltIcon color="primary"></RestartAltIcon>
                    </IconButton>
                    <IconButton onClick={async () => await stopServer(server.serverName)}>
                        <StopIcon color="error"></StopIcon>
                    </IconButton>
                </TableCell>
                :
                <TableCell />
                }
                <TableCell>
                    {server.serverName}
                </TableCell>
                <TableCell>
                    {server.minecraftVersion}
                </TableCell>
                <TableCell>
                    {`${serverTypeFromIdent(server.serverType)}`}<br />
                    {<a href={server.modpackUrl}>{`${modpackFromModpackUrl(server.serverType, server.modpackUrl)}`}</a>}
                </TableCell>
                <TableCell>
                    mc.teamnoos.dk:{server.port}
                </TableCell>
                <TableCell>
                    {`${server.status ? `${server.status.charAt(0).toUpperCase()}${server.status.slice(1)}` : 'Unknown'}` + `${server.players ? ` (${server.players.online}/${server.players.max})` : ''}`}
                </TableCell>
            </TableRow>
        )
    });

    return (
        <React.Fragment>
        <br />
        <TableContainer>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Server Actions
                    </TableCell>
                    <TableCell>
                        Server Name
                    </TableCell>
                    <TableCell>
                        Minecraft version
                    </TableCell>
                    <TableCell>
                        Server Type
                    </TableCell>
                    <TableCell>
                        Server Address
                    </TableCell>
                    <TableCell>
                        Server Status
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {listItems}
            </TableBody>
        </TableContainer>
        </React.Fragment>)
}