import { useState } from 'react';
import { Tabs, Tab, Container, Box, Switch, FormGroup, FormControlLabel } from '@mui/material';
import TabPanel from './tabPanel';
import { useAuth0 } from '@auth0/auth0-react';
import AdminPanel from './adminPanel';
import ControlPanel from './controlPanel';
import { Logout } from './authentication';

function App() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const [ selectedTab, setSelectedTab] = useState(0)
  const [ showOwnServers, setshowOwnServers] = useState(false)

  if (isLoading) return <div />

  if (!isAuthenticated) {
    loginWithRedirect({})
    return <div />
  };

  return (
    <div>
      <Logout />
      <Container>
        <Box>
          <h1>Control Panel</h1>
          <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
            <Tab label="Servers" />
            <Tab label="Admin" />
          </Tabs>
          <FormGroup>
            <FormControlLabel control={<Switch onChange={(_, checked) => setshowOwnServers(checked)}/>} label="Show your servers only" />
          </FormGroup>
          <TabPanel value={selectedTab} index={0}>
            <ControlPanel showOwnServers={showOwnServers} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <AdminPanel showOwnServers={showOwnServers} />
          </TabPanel>
        </Box>
      </Container>
    </div>
  );
}

export default App;