import { Alert, Button, Dialog, IconButton, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import CreateServer from "./createServer";
import DeleteDevice from "./deleteDevice";
import Server from "./models/server";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchServers, isAdmin, modpackFromModpackUrl, serverTypeFromIdent } from "./util";

interface AdminPanelProps {
    showOwnServers: boolean
}

export default function AdminPanel(props: AdminPanelProps) {
    const [error, setError] = useState('')
    const [showModal, setShowModal] = useState('')
    const [servers, setServers] = useState<Server[]>([])
    const { user, getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        getAccessTokenSilently().then(async token => {
            setServers(await fetchServers(token))
        })
        
        const interval = setInterval(async () => {
            setServers(await fetchServers(await getAccessTokenSilently()))
        }, 20000)

        return () => {
            clearInterval(interval)
        }
    }, []);

    async function SetupServer(serverName: string, serverType: string, modpackUrl: string, minecraftVersion: string) {
        let request = `${process.env.REACT_APP_API_DOMAIN}/create-server?serverName=${serverName}&user=${user?.nickname}&serverType=${serverType}&minecraftVersion=${minecraftVersion}`
        
        if (modpackUrl) request += `&modpackUrl=${encodeURIComponent(modpackUrl)}`
        
        try {
            const response = await fetch(request, {
                headers: {
                    Authorization: `Bearer ${await getAccessTokenSilently()}`
                }
            })

            const responseJson = await response.json()

            if(responseJson.status !== 'success') {
                setError(responseJson.data)
            }

        } catch (e) {
            setError(`Failed to create server!`)
        }
    }

    const deviceModals = servers.map((server: Server) => {
        return (
            <div>
                <Dialog open={showModal === 'delete ' + server.serverName} onClose={() => setShowModal('')}>
                    <DeleteDevice device={server} closeHandler={() => setShowModal('')} />
                </Dialog>
            </div>
        )
    })

    const listItems = servers.filter((server: Server) => {
        if (!props.showOwnServers) {
            return true
        }

        return server.creator === user?.nickname
    })
    .map((server: Server) => {
        return (
            <TableRow key={server.serverName}>
                {server.creator === user?.nickname || isAdmin(user?.nickname!) ?
                <TableCell>
                    <IconButton onClick={() => setShowModal('delete ' + server.serverName)}>
                        <DeleteIcon color="error"></DeleteIcon>
                    </IconButton>
                </TableCell>
                :
                <TableCell/>
                }
                <TableCell>
                    {server.serverName}
                </TableCell>
                <TableCell>
                    {server.minecraftVersion}
                </TableCell>
                <TableCell>
                    {`${serverTypeFromIdent(server.serverType)}`}<br />
                    {<a href={server.modpackUrl}>{`${modpackFromModpackUrl(server.serverType, server.modpackUrl)}`}</a>}
                </TableCell>
                <TableCell>
                    mc.teamnoos.dk:{server.port}
                </TableCell>
                <TableCell>
                    {`${server.status ? `${server.status.charAt(0).toUpperCase()}${server.status.slice(1)}` : 'Unknown'}` + `${server.players ? ` (${server.players.online}/${server.players.max})` : ''}`}
                </TableCell>
            </TableRow>
        )
    });

    const err = error !== '' ? <Alert severity="error">{error}</Alert> : <></>

    return (
        <div>
        <br />
        <Button variant="contained" color="success" onClick={() => setShowModal('add')}>Create new server</Button>
        <Dialog open={showModal === "add"} onClose={() => setShowModal('')}>
            <CreateServer closeHandler={() => setShowModal('')} submitHandler={SetupServer} />
        </Dialog>
        {err}
        <TableContainer>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Delete Server
                    </TableCell>
                    <TableCell>
                        Server Name
                    </TableCell>
                    <TableCell>
                        Minecraft Version
                    </TableCell>
                    <TableCell>
                        Server Type
                    </TableCell>
                    <TableCell>
                        Server Address
                    </TableCell>
                    <TableCell>
                        Server Status
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {listItems}
            </TableBody>
        </TableContainer>
        {deviceModals}
        </div>)
}